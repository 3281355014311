/*
	default background blue color;
*/
$playstation-blue: #0071ce;

/*
	default background color on hover for all elements;
*/
$on-hover-background-color: #D8E2F0;

/*
	default background color for all elements;
*/
$default-background-color: #fff;

/*
	default background color for shadow on all elements;
*/
$default-box-shadow-color: #f2f2f2;

/*
	white color;
*/
$default-white-color: #000;

$dark-blue: #005B99;

$warning-red: #C64645;