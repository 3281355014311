@import "../../Variables";

.users-container {
  h1 {
    font-size: 24px;
    padding: 20px 0;
  }
  .page-content {
    width: 500px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(0,0,0,.25);
    padding: 20px;
  }

  .remove-role {
    margin-top:-24px;
  }

  .roles-table table {
    box-shadow: none;
  }

  table.table td.roles-table {
    padding: 0;
  }

  table.table td {
    vertical-align: top;
  }

  .cancel-button {
    margin-right: 10px;
  }
}

.user-row {
  border-bottom: 1px solid #ccc;
}
