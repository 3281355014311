.ellipsis {
  & > a {
    border: none;
    background: none;
    pointer-events: none;
  }
}

.pagination-label-container {
  padding-top: 7px;
}

.numbered-pagination-container {
  font-size: 14px;
  margin-top: 15px;
}

.select-pagination-option-container {
  display: inline-block;
  margin-left: 20px;
}