@import "Variables";

/*
	default style for any button;
*/
button.primary-button-common {
	background-color: $playstation-blue;
	border-color: $playstation-blue;
	padding: 2px 15px;
	border-radius: 3px;
    line-height: 2;
	> .create-button-symbol {
		padding-right: 10px;
	}
}

button.default-button-common {
	border-color: $playstation-blue;
	border-radius: 3px;
	padding: 2px 15px;
  line-height: 2;
}

label.page-title {
  font-weight: normal;
}

.page-title-section {
  > div > div > h1  {
      font-size: 2em !important;
  }
}

.page-title {
  font-size: 36px !important;
}

.page-container {
  max-width: 1240px !important;
  //width: 95% !important;
  //max-width: 95% !important;
  padding: 0 !important;
}

/*
  Truncate text appearing in a span element.
 */
span.truncate-text {
  display:inline-block;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  max-width: 5ch;
  line-height: normal;
  vertical-align: middle;
  &.char-20 {
    max-width: 20ch;
  }
  &.char-25 {
    max-width: 25ch;
  }
  &.char-50 {
    max-width: 50ch;
  }
  &.char-83 {
    max-width: 83ch;
  }
}

.control-label {
  font-size: 17px;
}

.center-aligned {
  text-align: center;
}

/*
  List Headers
 */
.campaign-list-header,
.history-list-header,
.channel-list-header{
  line-height: 4;
  font-weight: bold;
  border-bottom: 4px solid $playstation-blue;
  background-color: white;
  box-shadow: -1px 0px 8px 1px $default-box-shadow-color, -1px 0px 8px -1px $default-box-shadow-color, 1px 0px 8px -1px $default-box-shadow-color;
}

.campaign-list-header {
  font-size: 12px;
}

#globalFooter {
  z-index: 9999;
  //position: fixed;
  //bottom: 0;
  //width: 100%;
}

table.table {
  th, td {
    border: none;
    font-size: 13px;
  }

  td {
    line-height: 2;
    vertical-align: middle;

    .btn-link {
      padding: 0;
      //vertical-align: top;
    }
  }

  th {
    border-bottom: 5px solid $playstation-blue;
    background: #fff;
  }
}

button.btn {
  box-shadow: 0 0 5px rgba(0,0,0,.3);

  nav &, &.btn-link, &.action-button {
    box-shadow: none;
  }

  span {
    font-weight:bold;
    font-size: 30px;
    vertical-align: middle;
    line-height:0;
    margin-right:5px;
    margin-top:-9px;
    display: inline-block;
  }
}

div.modal-header {
  background: $dark-blue;
  color: #fff;
}

div.modal-title {
  font-weight:normal;
  font-size: 1.3rem;
}

div.form-group {
  margin-bottom:1.5rem;
}

.modal-header > button > span {
  font-size: 20px;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

button.close {
  opacity: 1;
  vertical-align: middle;
}

.modal-dialog {
  max-width: 900px !important;
}


.modal-body {
  font-size: 14px;
  > form {
    max-width: 700px;
    margin: 0 auto;
    label {
      font-size: 14px;
    }
    input, textarea {
      font-size: 14px;
      border-radius: 3px;
      &:not([disabled]) {
        &::placeholder {
          color: #c3c3c3;
        }
      }
      &:disabled {
        border-radius: 0;
        border: none;
        &::placeholder {
          color: transparent;
        }
      }
    }
    .form-row > .col:nth-child(2) {
      padding-left: 50px
    }
  }
}

table.table-hover tbody tr:hover {
  background-color: $on-hover-background-color;
}

.search-bar {
  height: 35px;
  border: 1px solid #999999;
  border-radius: 4px;
  div {
    padding: 0px;
    margin: 0px;
  }
  > div {
    float: right;
    width: 400px;
    display: inline-block;
    position: relative;
    input {
      width: 100%;
      height: 98%;
      border: none;
      padding-left: 11px;
      padding-right: 10%;
      font-size: 14px;
      &::placeholder {
        color: #999;
      }
    }
    select {
      border: none;
      border-right: 2px solid #eee;
      color: #999;
      height: 32px;
    }
    i {
      position: absolute;
      right: 12px;
      top: 5px;
      color: #999;
      border-left: 2px solid #eee;
      line-height: 22px;
      font-size: 16px;
      padding: 2px 0 0 11px;
    }
  }
}

.async-search-bar {
  height: 35px;
  border: 1px solid #999999;
  border-radius: 4px;

  i {
    position: absolute;
    right: 12px;
    top: 5px;
    color: #999;
    border-left: 2px solid #eee;
    line-height: 22px;
    font-size: 16px;
    padding: 2px 0 0 11px;
  }

  > div {
    float: right;
    width: 400px;
    display: inline-block;
    position: relative;
    padding: 0px;
    margin: 0px;

    select {
      width: 100%;
      border: none;
      border-radius: 0;
      border-right: 1px solid #999999;
      font-size: 14px;
      color: #999;
      height: 32px;

      &::placeholder {
        color: #999;
      }
    }

    div.css-1wy0on6 {
      display: none;
    }

    div.css-151xaom-placeholder {
      top: 42%;
      color: #999 !important;
      font-weight: normal;
      font-size: 14px;
      font-weight: 400;
    }

    div.css-14ojxsb-control {
      min-height: 35px;
    }

    div.css-bg1rzq-control {
      border: none;
      background: transparent;
    }
  }

}

.news-container,
.users-container {
  th {
    //font-size: 14px;
    background: #fff;
  }

  .campaign-row {
    box-shadow: none;
    border: 0;
  }

  table {
    box-shadow: 0 0 5px rgba(0,0,0, .25);
  }

  .page-title {
    font-size: 24px !important;
    font-weight: normal;
  }

  .news-list-header > th.sortable-header {
    cursor: pointer;
    word-break: break-word;
    min-width: 60px;
    &.asc::after {
      content: ' \25B2' !important;
    }
    &.desc::after {
      content: ' \25BC' !important;
    }
  }
}

.has-error .error-msg:after {
  content: "!";
  display: inline-block;
  color: #fff;
  position: absolute;
  width: 10px;
  height: 10px;
  text-align: center;
  left: 0;
  top: 5px
}

.scheduling-subTab-body .mb-3 {
  margin-bottom: 0!important
}

.has-error .error-msg {
  color: red;
  padding-top: 5px;
  font-size: .9em
}

.has-error .error-msg:before {
  margin-left: 0;
  margin-right: 5px
}

.has-error input,.has-error select,.has-error textarea {
  border-color: red
}
