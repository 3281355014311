@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import './Variables';
@import './Glyphicons';

@font-face {
  font-family: 'SSTPro';
  src: url('./fonts/SSTPro-Roman.otf') format("opentype");
}

@font-face {
  font-family: 'SSTPro';
  font-weight: bold;
  src: url('./fonts/SSTPro-Medium.otf') format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SSTPro', 'Open Sans', sans-serif !important;
  background: $default-background-color !important;
  overflow: scroll;
}

.hidden {
  display: none !important;
}

a.nav-link {
  color: #fff;
  vertical-align: middle;
  padding: 6px 20px !important;
  font-size: 14px;

  .primary-header & {
    color: #fff !important;
  }

  .nav-tabs & {
    color: #337ab7;
  }

  &.active {
    background-color: #FAFAFA;
    color: black !important;
  }
}

button.dropdown-toggle {
  font-size: 14px;
  vertical-align: middle;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
}
.nav-tabs {
  line-height: 45px;
  border-bottom: 1px solid #ddd !important;
}

.navbar-nav > * {
  display: inline-block;
}

.dropdown.show {
  display: inline-block !important;


  .dropdown-menu > * {
    display: block;
    padding: 0 10px;
  }
}

.messageGroups-subTabs-panels.nav-tabs {
  a.nav-link {
    padding: 10px 15px;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    position: relative;
    display: inline-block;

    &.active {
      color: #555;
      cursor: default;
      background-color: #fff;
      border: 1px solid;
      border-color: #ddd #ddd transparent;
      border-bottom: 1px solid #fff;
      margin-bottom:-2px;
    }
  }
}

#basic-nav-dropdown,
#basic-nav-dropdown-admin,
#basic-nav-dropdown-audience {
  background: none;
  border: none;

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  //border-bottom: 1px solid #eee;
}

.tab-content label {
  font-weight:bold;
}

select.form-control,
button {
  font-size: 14px !important;
}

.card-header-tabs {
  margin-right:0 !important;
}

li.nav-link.disabled {
  pointer-events: auto;
}

label.form-check-label {
  font-weight: normal;
}

#campaign-tab-tabpane-messageGroups input[name="alwaysOn"] {
  margin-left: 10px;
}

.pull-right {
  float: right;
}

#globalHeader, .navbar.primary-header {
  position: fixed;
}

#globalHeader {
  width: 100%;
  top: 0;
  z-index: 9999;
}

.navbar.primary-header {
  top: 60px;
  width: 100%;
  z-index: 9998;

  @media (max-width: 1199px) {
    top: 40px;
  }
}

.gui-site-content {
  padding-top: 109px;
}

div.modal {
  z-index: 9999;
  top: 0px;
}

.one-line-component {
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

th.sortable-header {
  cursor: pointer;
  word-break: break-word;
  &.asc::after,
  &.reverse-arrow.desc::after {
    content: ' \25B2' !important;
  }
  &.desc::after,
  &.reverse-arrow.asc::after {
    content: ' \25BC' !important;
  }
}

/* Toastr */
#toast-container {
  width: 100%;
  left: 0;
  top: 100px;
  //position: absolute;
  font-size: 14px !important;

  & > div {
    width: 100%;
    opacity: 1;
    border-radius: 0;
  }
  & > .toast-success {
    width: 100%;
    max-width: 100%;
    background-color: #51A351;
  }

  & > .toast-error {
    background-image: none !important;
    width: 100%;
    max-width: 100%;
    background-color: #BD362F;
    &::before {
      content: '!';
      color: #fff;
      position: absolute;
      left: 20px;
      font-size: 30px;
      top: 5px;
    }
    &::after {
      content: 'x';
      color: #333;
      position: absolute;
      right: 20px;
      font-size: 20px;
      top: 10px;
      display: none;

    }

  }
}

.toast {
  font-size: 14px !important;
}

.iptools-common-ui-header {
  top: 60px;
  @media (max-width: 1199px) {
    top: 40px;
  }
  .pucl-local-navigation {
    .dropdown {
      .dropdown-menu {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
  }
}
