.spinner-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #363636;
    margin: auto;
    padding: 25px;
    height: 100%;
}

.inline .spinner-loader {
    padding: 0;
}

.rotate-spinner {
    animation: 2s linear infinite;
    animation-name: rotate-spinner;
}

.push-spinner-left {
    margin-right: 1em
}

.push-spinner-right {
    margin-left: 1em
}

.push-spinner-down {
    margin-top: 2px;
}

@keyframes rotate-spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
