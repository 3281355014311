.nav-bar-table-container {
  .pucl-action-bar {
    z-index: 0 !important;
  }

  .pucl-table-wrapper {
    margin-bottom: 16px;
  }

  .pucl-data-table {
    z-index: 0;
  }

  .row-cell-content {
    padding-left: 16px;
  }
}

.nav-bar-modal {
  .nav-bar-modal-body {
    width: auto !important;
  }
}
